import { Capacitor } from '@capacitor/core';
import { AppReview } from '@capawesome/capacitor-app-review';
import { useIonToast } from '@ionic/react';
import React, { useState } from 'react';

import { sendFeedback } from '../../../api/support';
import B1Button from '../../buttons/B1Button';
import B1Modal from '../B1Modal';
import FeedbackForm from '../feedback-modal/FeedbackForm';

import './RatingModal.scss';

interface RatingModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const RatingModal: React.FC<RatingModalProps> = ({ open, setOpen }) => {
  const [presentToast] = useIonToast();
  const [ratingMode, setRatingMode] = useState<boolean>(true);

  return (
    <B1Modal className='rating-modal' open={open} onModalClose={() => setOpen(false)}>
      {ratingMode ? (
        <>
          <h1 className='emoji'>🫡</h1>
          <h2 className='no-hyphens'>
            Gefällt Dir unsere {Capacitor.isNativePlatform() ? 'App' : 'Lernplattform'}?
          </h2>
          <div className='rating-buttons'>
            <B1Button
              className='button-block button-green'
              click={() => {
                sendFeedback(undefined, true);
                setOpen(false);

                if (Capacitor.isNativePlatform()) {
                  AppReview.requestReview();
                }
              }}
            >
              Ja 😊
            </B1Button>
            <B1Button className='button-block button-red' click={() => setRatingMode(false)}>
              Nein 😔
            </B1Button>
          </div>
        </>
      ) : (
        <>
          <h1 className='emoji'>😔</h1>
          <h2 className='no-hyphens'>Was können wir besser machen?</h2>
          <FeedbackForm
            positiveRating={false}
            onFeedbackSent={() => {
              setOpen(false);

              presentToast({
                message: 'Vielen Dank für Dein Feedback!',
                duration: 3000,
                position: 'bottom',
                color: 'success',
              });
            }}
          />
        </>
      )}
    </B1Modal>
  );
};

export default RatingModal;
