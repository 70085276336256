import { SavedQuestionOverview } from '../../api/interfaces/saved-questions';
import { SmartmodeLesson } from '../../api/interfaces/smartmode';
import { getCourseOrderIndex } from '../../utils/course-util';

export interface SmartmodeCourse {
  identifier: string;
  lessons: SmartmodeLesson[];
  progress: number;
  questionsPdf?: string;
  savedQuestions?: SavedQuestionOverview;
}

type SmartmodeData = {
  courses: SmartmodeCourse[];
};
type SmartmodeAction =
  | { type: 'RESET_COURSES' }
  | {
      type: 'ADD_COURSE';
      identifier: string;
      lessons: SmartmodeLesson[];
      progress: number;
      questionsPdf?: string;
    }
  | { type: 'ADD_SAVED_QUESTIONS'; identifier: string; savedQuestions: SavedQuestionOverview }
  | { type: 'SORT_COURSES' };

export const examSimulatorReducer = (
  state: SmartmodeData,
  action: SmartmodeAction
): SmartmodeData => {
  switch (action.type) {
    case 'RESET_COURSES':
      return { ...state, courses: [] };
    case 'ADD_COURSE':
      return {
        ...state,
        courses: [
          ...state.courses,
          {
            identifier: action.identifier,
            lessons: action.lessons,
            progress: action.progress,
            questionsPdf: action.questionsPdf,
          },
        ],
      };
    case 'ADD_SAVED_QUESTIONS':
      return {
        ...state,
        courses: state.courses.map((course) => {
          if (course.identifier === action.identifier) {
            return {
              ...course,
              savedQuestions: action.savedQuestions,
            };
          }

          return course;
        }),
      };
    case 'SORT_COURSES':
      return {
        ...state,
        courses: state.courses.sort((a, b) => {
          return getCourseOrderIndex(a.identifier) - getCourseOrderIndex(b.identifier);
        }),
      };
    default:
      return state;
  }
};
