export interface ChoiceQuestionAnswer {
  id: number;
  title: string;
  picture_link?: string;
  correct: boolean;
}

export interface TextQuestionAnswer {
  answer: string;
  picture_link?: string;
  user_points: number;
  total_points: number;
}

export enum QuestionType {
  SC = 'single',
  MC = 'multiple',
  TF = 'text',
}

export interface Question {
  id: number;
  title: string;
  type: QuestionType;
  hint?: string;
  hint_picture_link?: string;
  picture_link?: string;
  choice_answers?: ChoiceQuestionAnswer[];
  is_saved: boolean;
}

export type QuestionEvaluationStatus = 'evaluated' | 'not-evaluated' | 'evaluating';

export interface QuizQuestion extends Question {
  checkedAnswers?: number[];
  textAnswerInput?: string;
  textQuestionAnswer?: TextQuestionAnswer;
  evaluationStatus?: QuestionEvaluationStatus;
}
