import { useIonViewWillEnter } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import Carousel, { ArrowProps } from 'react-multi-carousel';
import { useParams } from 'react-router';

import { getExamPapers } from '../../api/courses/exam-simulator';
import { getSavedQuestionsOverview } from '../../api/courses/saved-questions';
import { CourseType } from '../../api/interfaces/course';
import { ExamPaper, hasMarginalPassed } from '../../api/interfaces/exam-paper';
import B1Button from '../../components/buttons/B1Button';
import SavedQuestions from '../../components/course/SavedQuestions';
import B1Modal from '../../components/modal/B1Modal';
import useWindowSize from '../../hooks/window-size-hook';
import { isApiError } from '../../utils/api-util';
import { getShortCourseName } from '../../utils/course-util';
import Layout from '../Layout';
import { examSimulatorReducer } from './exam-simulator-reducer';
import ExamPaperCard from './ExamPaperCard';
import { responsiveCarouselTypes } from './responsive-carousel';

import 'react-multi-carousel/lib/styles.css';
import './ExamSimulator.scss';

interface CustomArrowProps extends ArrowProps {
  direction: 'left' | 'right';
}

const CustomArrow: React.FC<CustomArrowProps> = ({ direction, onClick }) => {
  return (
    <div className={`carousel-arrow-container ${direction}-arrow-container`}>
      <button type='button' onClick={onClick} title={direction === 'left' ? 'Zurück' : 'Weiter'}>
        <img src={`assets/icons/arrow-${direction === 'left' ? 'back' : 'forward'}.svg`} />
      </button>
    </div>
  );
};

const ExamSimulator: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const pageRef = useRef<HTMLElement>(null);
  const [moreDetailsExamPaper, setMoreDetailsExamPaper] = useState<ExamPaper | undefined>();
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [presentingElement, setPresentingElement] = useState<HTMLElement>();
  const { width } = useWindowSize();
  const [examSimulatorData, dispatchExamSimulator] = React.useReducer(examSimulatorReducer, {
    courses: [],
  });

  useEffect(() => {
    setPresentingElement(pageRef?.current ?? undefined);
  }, [pageRef]);

  const loadExamPapers = useCallback(async () => {
    dispatchExamSimulator({ type: 'RESET_COURSES' });

    const courses: string[] = [];

    if (identifier.toLowerCase() === 'sbf') {
      courses.push(CourseType.SBFS);
      courses.push(CourseType.SBFB);
    } else if (identifier.toLowerCase() === 'psbf') {
      courses.push(CourseType.PSBFS);
      courses.push(CourseType.PSBFB);
    } else if (identifier.toLowerCase() === 'ubi') {
      courses.push(CourseType.UBI);
      courses.push(CourseType.UBIP);
    } else if (identifier.toLowerCase() === 'pubi') {
      courses.push(CourseType.PUBI);
      courses.push(CourseType.PUBIP);
    } else {
      courses.push(identifier);
    }

    for (const course of courses) {
      const res = await getExamPapers(course);

      if (isApiError(res)) {
        console.error(res);
        continue;
      }

      dispatchExamSimulator({
        type: 'ADD_EXAM_PAPERS',
        identifier: course,
        examPapers: res.exam_papers,
      });
      dispatchExamSimulator({
        type: 'SET_EXAM_PAPERS_DOCUMENT',
        identifier: course,
        examPapersDocument: res.exam_papers_path,
      });

      getSavedQuestionsOverview(course, 'exam').then((res) => {
        if (isApiError(res)) {
          console.error(res);
          return;
        }

        dispatchExamSimulator({
          type: 'ADD_SAVED_QUESTIONS',
          identifier: course,
          savedQuestions: res,
        });
      });
    }
  }, [identifier]);

  useIonViewWillEnter(() => {
    loadExamPapers();
  }, [loadExamPapers]);

  return (
    <Layout
      pageRef={pageRef}
      backButton={`/course/${identifier}`}
      contentClassName='exam-simulator'
      currentCourseType={identifier}
    >
      <h1>Prüfungssimulation</h1>
      <div className='exam-simulator-intro'>
        <p>Herzlich willkommen zur Prüfungssimulation!</p>
        {identifier.toLowerCase() === 'sbf' ? (
          <p>
            In diesem Bereich kannst Du gezielt für Deine Prüfung der Single-Choice-Fragen üben. Du
            findest hier die offiziellen Prüfungsbögen, wovon einer in der Prüfung abgefragt wird.
            Es reicht demnach vollkommen aus, wenn Du neben der Navigation jeden dieser Bögen hier
            lernst.
          </p>
        ) : identifier.toLowerCase() === 'bsp' ? (
          <p>
            In diesem Bereich kannst Du gezielt für Deine Prüfung der Single-Choice-Fragen üben. Du
            findest hier Prüfungsbögen, die sehr nahe an den originalen Prüfungsbögen sind. Anders
            als beim Sportbootführerschein gibt es beim Bodenseeschifferpatent keine
            veröffentlichten Prüfungsbögen, die exakt so in der Prüfung dran kommen. Unsere Bögen
            sind aber in Absprache mit dem Prüfungsamt entstanden und somit sehr ähnlich.
          </p>
        ) : identifier.toLowerCase() === 'src' || identifier.toLowerCase() === 'ubi' ? (
          <p>
            In diesem Bereich kannst Du gezielt für Deine Prüfung der Single-Choice-Fragen üben. Du
            findest hier die offiziellen Prüfungsbögen, wovon einer in der Prüfung abgefragt wird.
            Es reicht demnach vollkommen aus, wenn Du jeden dieser Bögen hier lernst.
          </p>
        ) : identifier.toLowerCase() === 'sks' ? (
          <p>
            In diesem Bereich kannst Du gezielt für Deine schriftliche Prüfung üben. Du findest hier
            die offiziellen Prüfungsbögen, wovon einer in der Prüfung abgefragt wird. Es reicht
            demnach vollkommen aus, wenn Du neben den Kartenaufgaben jeden dieser Bögen hier lernst.
          </p>
        ) : identifier.toLowerCase() === 'fkn' || identifier.toLowerCase() === 'skn' ? (
          <p>
            In diesem Bereich kannst Du gezielt für Deine Prüfung der Multiple-Choice- und
            schriftlichen Fragen üben. Du findest hier die offiziellen Prüfungsbögen, wovon einer in
            der Prüfung abgefragt wird. Es reicht demnach vollkommen aus, wenn Du jeden dieser Bögen
            hier lernst.
          </p>
        ) : (
          <></>
        )}
        <p>
          In der Prüfungssimulation lernst Du unter Prüfungsbedingungen – erst am Ende wird Dir
          angezeigt, ob Du den Bogen bestanden hast oder nicht.
        </p>
        <p>Zusätzlich kannst Du Dir auch alle Fragebögen als PDF herunterladen.</p>
        <p>Wir wünschen Dir viel Erfolg!</p>
        {(identifier.toLowerCase() === 'ubi' || identifier.toLowerCase() === 'pubi') && (
          <p>
            <strong>Hinweis:</strong> Die unterstehenden Prüfungsbögen mit dem Titel &quot;UBI
            Ergänzungsprüfung&quot; dienen zum Lernen für Besitzer der Funkzeugnisse ROC, GOC, LRC
            oder SRC.
          </p>
        )}
        {identifier.toLowerCase() === 'fkn' && (
          <p>
            <strong>Hinweis:</strong> Die Bögen 1-4 sind für Prüfungen beim DSV, während die Bögen
            5-8 für Prüfungen beim DMYV relevant sind.
          </p>
        )}
      </div>

      {examSimulatorData.courses.map((course, i) => (
        <div key={i} className='exam-course'>
          <div className='exam-papers-header'>
            <h5>
              {course.identifier === CourseType.UBIP || course.identifier === CourseType.PUBIP
                ? 'UBI Ergänzungsprüfung'
                : getShortCourseName(course.identifier)}
            </h5>
            {course.examPapersDocument && (
              <B1Button
                className='button-sm button-transparent-light'
                href={course.examPapersDocument}
                downloadAndOpen
              >
                PDF
              </B1Button>
            )}
          </div>
          {width !== undefined &&
            (width >= 576 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                keyBoardControl={false}
                responsive={responsiveCarouselTypes}
                partialVisible
                containerClass='exam-papers-container'
                customLeftArrow={<CustomArrow direction='left' />}
                customRightArrow={<CustomArrow direction='right' />}
              >
                {course.examPapers?.map((examPaper, j) => (
                  <div className='exam-paper-container' key={j}>
                    <img src='assets/icons/glow-circle.svg' draggable={false} />
                    <div className='connection-line'></div>
                    <ExamPaperCard
                      course={course}
                      examPaper={examPaper}
                      onMoreDetailsClick={() => {
                        setMoreDetailsExamPaper(examPaper);
                        setStatsModalOpen(true);
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <Row className='exam-papers-container gx-2 gy-3'>
                {course.examPapers?.map((examPaper, j) => (
                  <Col key={j} xs={6}>
                    <div className='exam-paper-container'>
                      <ExamPaperCard
                        course={course}
                        examPaper={examPaper}
                        onMoreDetailsClick={() => {
                          setMoreDetailsExamPaper(examPaper);
                          setStatsModalOpen(true);
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
        </div>
      ))}

      <SavedQuestions courses={examSimulatorData.courses} type='exam' />

      <B1Modal
        open={statsModalOpen}
        onModalClose={() => setStatsModalOpen(false)}
        className='exam-paper-stats-modal'
        showFooterCloseButton={width !== undefined && width < 576}
        mode={width !== undefined && width < 576 ? 'ios' : undefined}
        presentingElement={width !== undefined && width < 576 ? presentingElement : undefined}
      >
        <h1 className='emoji'>📊</h1>
        <h2 className='no-hyphens mb-4'>Statistiken</h2>
        <div className='table-responsive'>
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Status</th>
                <th>
                  {(moreDetailsExamPaper?.total_points ?? 0) >
                  (moreDetailsExamPaper?.questions_count ?? 0)
                    ? 'Punkte'
                    : 'Richtig'}
                </th>
                <th>Kategorien</th>
              </tr>
            </thead>
            <tbody>
              {[...(moreDetailsExamPaper?.submissions ?? [])]
                .sort(
                  (a, b) =>
                    DateTime.fromISO(b.created_at).toMillis() -
                    DateTime.fromISO(a.created_at).toMillis()
                )
                .map((submission, i, submissions) => (
                  <tr key={i}>
                    <td>{submissions.length - i}</td>
                    <td
                      className={
                        submission.passed
                          ? submission.categories.some(hasMarginalPassed)
                            ? 'exam-marginal-passed'
                            : 'exam-passed'
                          : 'exam-failed'
                      }
                    >
                      {submission.passed
                        ? submission.categories.some(hasMarginalPassed)
                          ? 'Knapp bestanden'
                          : 'Bestanden'
                        : 'Durchgefallen'}
                    </td>
                    <td>
                      {submission.categories.reduce((a, b) => a + b.correct, 0)} /{' '}
                      {submission.categories.reduce((a, b) => a + b.total, 0)}
                    </td>
                    <td>
                      <ul className='exam-categories'>
                        {submission.categories.map((category, j) => (
                          <OverlayTrigger
                            key={j}
                            overlay={
                              <Tooltip className='b1-tooltip'>
                                <p>
                                  <strong>{category.title}</strong>
                                </p>
                                <span>
                                  {(moreDetailsExamPaper?.total_points ?? 0) >
                                  (moreDetailsExamPaper?.questions_count ?? 0)
                                    ? 'Punkte'
                                    : 'Richtig'}{' '}
                                  / Benötigt / Gesamt
                                </span>
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}
                          >
                            <li>
                              {category.identifier}{' '}
                              <strong
                                className={
                                  category.correct >= category.correct_to_pass
                                    ? hasMarginalPassed(category)
                                      ? 'exam-marginal-passed'
                                      : 'exam-passed'
                                    : 'exam-failed'
                                }
                              >
                                {category.correct}
                              </strong>{' '}
                              / {category.correct_to_pass} / {category.total}
                            </li>
                          </OverlayTrigger>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </B1Modal>
    </Layout>
  );
};

export default ExamSimulator;
