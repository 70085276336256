import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { SavedQuestion, SavedQuestionOverview } from '../interfaces/saved-questions';
import { SmartmodeQuestion } from '../interfaces/smartmode';

export const getSavedQuestions = async (identifier: string, type: 'learn' | 'exam') => {
  const res = await makeApiRequest<SavedQuestion[]>(
    'GET',
    `v1/courses/${identifier.toLowerCase()}/saved-questions/${type}`
  );

  if (isApiError(res)) {
    return res;
  }

  const questions: SmartmodeQuestion[] = res.data.map((question) => {
    return {
      ...question,
      is_saved: true,
    };
  });

  return questions;
};

export const getSavedQuestionsOverview = async (identifier: string, type: 'learn' | 'exam') => {
  const res = await makeApiRequest<SavedQuestionOverview>(
    'GET',
    `v1/courses/${identifier.toLowerCase()}/saved-questions/${type}/overview`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const addSavedQuestion = async (
  identifier: string,
  questionId: number,
  type: 'learn' | 'exam'
) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${identifier.toLowerCase()}/saved-questions/add`,
    undefined,
    {
      questionId,
      type,
    }
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const removeSavedQuestion = async (
  identifier: string,
  questionId: number,
  type: 'learn' | 'exam'
) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${identifier.toLowerCase()}/saved-questions/remove`,
    undefined,
    {
      questionId,
      type,
    }
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const updateSavedQuestionProgress = async (
  identifier: string,
  questionId: number,
  correct: boolean,
  type: 'learn' | 'exam'
) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${identifier.toLowerCase()}/saved-questions`,
    undefined,
    {
      questionId,
      type,
      correct,
    }
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const resetSavedQuestionProgress = async (identifier: string, type: 'learn' | 'exam') => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${identifier.toLowerCase()}/saved-questions/reset-progress`,
    undefined,
    {
      type,
    }
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};
