import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import OverlayTrigger, { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { QuizQuestion } from '../../api/interfaces/quiz';
import { SavedQuestionOverview } from '../../api/interfaces/saved-questions';
import { getShortCourseName } from '../../utils/course-util';
import { wait } from '../../utils/misc-util';

import './SavedQuestions.scss';

interface SaveQuestionButtonProps {
  question?: QuizQuestion;
  onSaveQuestionClick?: () => void;
}

export const SaveQuestionButton: React.FC<SaveQuestionButtonProps> = ({
  question,
  onSaveQuestionClick,
}) => {
  const [show, setShow] = useState(false);
  const [isTouchscreen, setIsTouchscreen] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    setIsTouchscreen(window.matchMedia('(pointer: coarse)').matches);
  }, []);

  useEffect(() => {
    if (hidden) {
      if (question?.is_saved) {
        setTooltipText(`Frage nicht mehr ${isTouchscreen ? 'gespeichert' : 'speichern'}`);
      } else {
        setTooltipText(`Frage ${isTouchscreen && question ? 'gespeichert' : 'speichern'}`);
      }
    }
  }, [isTouchscreen, hidden, question?.is_saved]);

  const getOverlayTriggerTypes: () => OverlayTriggerType[] = () => {
    if (isTouchscreen) {
      return ['click'];
    } else {
      return ['hover', 'focus'];
    }
  };

  const SaveQuestionTooltip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip className='b1-tooltip' {...props}>
        {tooltipText}
      </Tooltip>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.currentTarget.blur();

    if (isTouchscreen) {
      wait(1000).then(() => setShow(false));
    }

    if (onSaveQuestionClick) {
      onSaveQuestionClick();
    }
  };

  return (
    <OverlayTrigger
      show={show}
      onToggle={setShow}
      placement='bottom'
      overlay={SaveQuestionTooltip}
      trigger={getOverlayTriggerTypes()}
      onEntering={() => setHidden(false)}
      onExited={() => setHidden(true)}
    >
      <button
        type='button'
        className='b1-button button-sm button-reverse-border scale-effect'
        onClick={handleClick}
      >
        {question?.is_saved ? <BsBookmarkFill /> : <BsBookmark />}
      </button>
    </OverlayTrigger>
  );
};

interface SavedQuestionCardProps {
  identifier: string;
  savedQuestionOverview?: SavedQuestionOverview;
  type: 'learn' | 'exam';
}

const SavedQuestionCard: React.FC<SavedQuestionCardProps> = ({
  identifier,
  savedQuestionOverview,
  type,
}) => {
  const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
    if (!savedQuestionOverview || savedQuestionOverview.questions_count === 0) {
      return <div className='b1-card disabled'>{children}</div>;
    } else {
      return (
        <Link
          className='b1-card scale-effect'
          to={`/course/${identifier.toLowerCase()}/smartmode/${type}`}
        >
          {children}
        </Link>
      );
    }
  };

  return (
    <Col xs={12} md={6} lg={4}>
      <Wrapper>
        <h4>{getShortCourseName(identifier)}</h4>
        <p className='mt-3 description text-muted'>
          Fragen: {savedQuestionOverview?.questions_count}
        </p>
        <p className='description text-muted'>
          Level 1/2/3: <span className='level-1'>{savedQuestionOverview?.level_1_count}</span>/
          <span className='level-2'>{savedQuestionOverview?.level_2_count}</span>/
          <span className='level-3'>{savedQuestionOverview?.level_3_count}</span>
        </p>
      </Wrapper>
    </Col>
  );
};
interface SavedQuestionsProps {
  courses: { identifier: string; savedQuestions?: SavedQuestionOverview }[];
  type: 'learn' | 'exam';
}

const SavedQuestions: React.FC<SavedQuestionsProps> = ({ courses, type }) => {
  return (
    <div className='saved-questions-container mt-5'>
      <h3 className='mb-3'>Gespeicherte Fragen</h3>
      <div className='simple-card card-white'>
        <SaveQuestionButton />
        <p>
          {/* Merke dir Fragen, die du nochmal intensiver üben möchtest, über den Button &quot;Frage
          speichern&quot; im Fragentrainer, Smartmodus oder der Auswertung im Prüfungsmodus. */}
          {/* Tipp: Merke dir Fragen, die Du nochmal intensiver üben möchtest, über den Button
          &quot;Frage speichern&quot;. */}
          <strong>Tipp:</strong> Merke Dir Fragen zum intensiven Üben über diesen Button.
        </p>
      </div>
      <Row className='gy-4'>
        {courses.map((course, i) => (
          <SavedQuestionCard
            key={i}
            identifier={course.identifier}
            savedQuestionOverview={course.savedQuestions}
            type={type}
          />
        ))}
      </Row>
    </div>
  );
};

export default SavedQuestions;
