import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { UseIonLoadingResult } from '@ionic/react';
import { Duration } from 'luxon';

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, Math.max(0, ms)));

export const formatDuration = (seconds: number) => {
  return Duration.fromObject({ seconds: seconds }).toFormat('hh:mm:ss') + ' h';
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

  return emailRegex.test(email);
};

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const downloadAndOpenFile = async (url?: string, loading?: UseIonLoadingResult) => {
  if (!url) return;

  if (Capacitor.isNativePlatform()) {
    const fileNameMatch = url.match(/.*\/(\S*?)\.pdf/);
    let path = `file-${Date.now()}.pdf`;

    if (fileNameMatch && fileNameMatch.length > 1) {
      path = `${fileNameMatch[1]}.pdf`;
    }

    loading?.[0]({
      message: 'Datei wird heruntergeladen...',
      duration: 0,
      mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md',
    });

    Filesystem.downloadFile({
      url: url,
      path: path,
      directory: Directory.Cache,
      recursive: true,
      webFetchExtra: {
        credentials: 'include',
        mode: 'cors',
      },
    }).then(
      (downloadedFile) => {
        loading?.[1]();

        if (downloadedFile.path) {
          FileOpener.openFile({ path: downloadedFile.path });
        }
      },
      (err) => {
        console.error(err);
        loading?.[1]();
        // TODO: Fehler-Toast anzeigen
      }
    );
  } else {
    window.open(url, '_blank');
  }
};
